import { Component, NgZone, OnDestroy } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed, 
  StatusBarStyle} from '@capacitor/core';

const { PushNotifications, StatusBar, SplashScreen } = Plugins;

import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { PopupmodelService } from './services/popupmodel.service';
import { HttpService } from './services/http.service';
declare var SMSReceive: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  constructor(
    private platform: Platform,
    private http: HttpService,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private router: Router,
    private popup: PopupmodelService, 
    private zone: NgZone,
    private popUp: PopupmodelService
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
        localStorage.setItem('deviceWidth',this.platform.width().toString());
        localStorage.setItem('deviceHeight',this.platform.height().toString());
        if(this.platform.is('mobile')){
          SplashScreen.hide();
          StatusBar.setBackgroundColor({ color: '#f3801d' });
          StatusBar.setStyle({ style: StatusBarStyle.Dark });
          this.initializeFCM();
          this.start();
        }
    });
  }
  initializeFCM(){
    // Android will just grant without prompting
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        sessionStorage.setItem('FCMToken',token.value);
        console.info('Push registration success, token: ' + token.value);
      }
    );
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        this.popUp.presentToast("New bill received, Refresh it","danger","top");
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
  ngOnDestroy() {
    let date = new Date();
    let formdata = new FormData();
    let data = {"Id":localStorage.getItem("sessionID"),"Session_end":date.toISOString(),"isOnline":'0'};
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',"update");
    formdata.append('table','session_log');
    this.http.postDataByForm('common-api.php',formdata)
            .then((data: any) => {
              this.popUp.dismisLoading();
              console.log(data);
            });
  }
  start() {
    SMSReceive.startWatch(
      () => {
        // console.log('watch started');
        document.addEventListener('onSMSArrive', (e: any) => {
          // console.log('onSMSArrive()');
          var IncomingSMS = e.data;
          // console.log('sms.address:' + IncomingSMS.address);
          // console.log('sms.body:' + IncomingSMS.body);
          /** Debug recived SMS content */
          // this.popup.presentToast(IncomingSMS.body);
          /* Debug received SMS content (JSON) */
          // console.log(JSON.stringify(IncomingSMS));
          this.processSMS(IncomingSMS);
        });
      },
      () => { 
        // console.log('watch start failed') 
      }
    )
  }
  stop() {
    SMSReceive.stopWatch(
      () => { console.log('watch stopped') },
      () => { console.log('watch stop failed') }
    )
  }
  processSMS(data: any) {
    // Check SMS for a specific string sequence to identify it is you SMS
    // Design your SMS in a way so you can identify the OTP quickly i.e. first 6 letters
    // In this case, I am keeping the first 6 letters as OTP
    const message = data.body;
    // this.popup.presentToast("SMS Processed :- "+message);
    // this.popUp.presentToast("Bill Paid! "+message.indexOf('Bill Paid!'));
    if (message && message.indexOf('Bill Paid!') != -1) {
      // let message = data.body;
      let formdata = new FormData();
      let record = {"Message": message};
      formdata.append('data',JSON.stringify(record));
      this.http.postDataByForm('read-sms.php',formdata)
               .then((data: any) => {
                //  this.popUp.dismisLoading();
                // this.popup.presentToast("SMS Processed :- "+JSON.stringify(data));
               });
      // console.log(message);
      // this.stop();
    }
  }
}
