import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tab',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./nav/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./auth/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'billfilter',
    loadChildren: () => import('./modals/billfilter/billfilter.module').then( m => m.BillfilterPageModule)
  },
  {
    path: 'new-user',
    loadChildren: () => import('./modals/new-user/new-user.module').then( m => m.NewUserPageModule)
  },
  {
    path: 'wallet-topup',
    loadChildren: () => import('./modals/wallet-topup/wallet-topup.module').then( m => m.WalletTopupPageModule)
  },
  {
    path: 'user-report',
    loadChildren: () => import('./modules/user-report/user-report.module').then( m => m.UserReportPageModule)
  },
  {
    path: 'filtersummary',
    loadChildren: () => import('./modals/filtersummary/filtersummary.module').then( m => m.FiltersummaryPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./models/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'userchange',
    loadChildren: () => import('./models/userchange/userchange.module').then( m => m.UserchangePageModule)
  },
  {
    path: 'add-biller',
    loadChildren: () => import('./models/add-biller/add-biller.module').then( m => m.AddBillerPageModule)
  },
  {
    path: 'add-card-data',
    loadChildren: () => import('./models/add-card-data/add-card-data.module').then( m => m.AddCardDataPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./nav/menu/menu.module').then( m => m.MenuPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
