import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  // serviceUrl: any = "http://localhost/nirved-apps/";
  // pageUrl: any = "http://localhost/nirved-apps/";
  serviceUrl: any = "https://apinew.nirvedservices.in/";
  pageUrl: any = "https://apinew.nirvedservices.in/";
  header: any = null;
  userToken: any = "be55b7f22ccf0b8-1-75ba8f090b9c69954";
  
  constructor(
    private http: HttpClient
  ) { 
    this.updateAuthToken();
  }
  updateAuthToken(){
    // console.log(sessionStorage.getItem('Auth-token'));
    if(sessionStorage.getItem('Auth-token')){
      this.userToken = sessionStorage.getItem('Auth-token');
    }
    this.header = new HttpHeaders({
      'X-Auth-Token': this.userToken
    });
  }
  // , {headers: this.header}
  getDataByPage(pageName: any) {
    return new Promise((resolve, reject) => {
      this.http.get(this.serviceUrl+pageName, { headers: this.header})
          .subscribe(data => {
            resolve(data);
          }, err => {
            reject(err);
          });
    });
  }
  getDataByUrl(pageName: any) {
    return new Promise((resolve, reject) => {
      this.http.get(pageName)
          .subscribe(data => {
            resolve(data);
          }, err => {
            reject(err);
          });
    });
  }
  patchDataByJson(pageName: any, pageData: any){
    this.updateAuthToken();
    return new Promise((resolve, reject) => {
      this.http.patch(this.serviceUrl+pageName, JSON.stringify(pageData), { headers: this.header})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            // console.log(err);
            reject(err);
          });
    });
  }
  postDataByJson(pageName: any, pageData: any){
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, JSON.stringify(pageData), { headers: this.header})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
    });
  }
  postDataByForm(pageName: any, pageData: any){
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, pageData, { headers: this.header})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
    });
  }
}
