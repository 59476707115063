import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';

const config = {
    apiKey: "AIzaSyAkCyME4jsrOvHDeKzzVNUNmWcYhtCDI8Q",
    authDomain: "api-309809.firebaseapp.com",
    projectId: "gmail-api-309809",
    storageBucket: "gmail-api-309809.appspot.com",
    messagingSenderId: "755915034181",
    appId: "1:755915034181:web:905601762c5ce06e03dd2c",
    measurementId: "G-P187WQ2QD8"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule, 
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    TableModule,
    AngularFireModule.initializeApp(config),
    // AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    // AngularFireStorageModule // storage
  ],
  providers: [
    // Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
